<template>
  <div class="body-bg">
    <div class="container">
         <van-nav-bar
      title="等级规则"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <van-cell>
       <p class="text" v-html="articleContent">
       </p>
    </van-cell>
    <!--
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item :name="index" v-for="(item, index) in articleList" :key="index">
          <template #title>
            <div class="title">
              {{item.title}}
            </div>
          </template>
          <p class="text" v-html="item.content">
          </p>
        </van-collapse-item>
      </van-collapse>
    -->
    </div>
  </div>
</template>

<script>
import BaseService from "@/api/base";
export default {
  data() {
    return {
      activeNames: "1",
      articleList: [],
      articleContent: "",
    };
  },
  async beforeMount() {
     const resp = await BaseService.getGradeArticle(this, {});
     if(resp.data.code == 10000) {
       this.articleList = resp.data.data;
       this.articleContent = resp.data.data[0].content
     }
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },
  }
};
</script>

<style scoped>
.container {
  background-color: #fff;
  box-shadow: 0px 0px 0px 0px rgba(223, 223, 223, 0.45);
  border-radius: 4px;
}
.title {
  font-size: 13px;
  color: #333;
}
.text {
  font-size: 13px;
  color: #808080;
}
</style>
